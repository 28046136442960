import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogVideo } from 'graphql-types';
import { BlogIndexPageTemplate } from '../components/Blog/blog-index-page-template';

interface BlogPageProps {
  data: {
    videos: {
      edges: {
        node: SanityBlogVideo;
      }[];
    };
  };
  pageContext: {
    totalNumberOfPages: number;
    pathPrefix: string;
  };
}

const BlogIndexPaginationTemplate = ({ data, pageContext }: BlogPageProps) => {
  const videos = data?.videos?.edges.map(edge => edge.node);
  const { totalNumberOfPages, pathPrefix } = pageContext;

  return (
    <BlogIndexPageTemplate
      pathPrefix={pathPrefix}
      posts={videos}
      totalNumberOfPages={totalNumberOfPages}
      variant="video"
    />
  );
};

export const query = graphql`
  query BlogVideoIndexPaginationQuery($limit: Int, $skip: Int) {
    videos: allSanityBlogVideo(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: publishedDate }
      filter: {
        slug: { current: { ne: null } }
        image: { asset: { id: { ne: null } } }
      }
    ) {
      edges {
        node {
          ...BlogVideoCardInfo
        }
      }
    }
  }
`;

export default BlogIndexPaginationTemplate;
